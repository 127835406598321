<template>
    <section id="banner" class="banner bg-elofrete-orange">
      <h2 class="elofrete-blue">Cadastre-se e tenha acesso a todas as informações</h2>
      <a href="https://app.elofrete.com.br/static/signup.xhtml" target="_blank" class="button-link w-2">Cadastre-se</a>
      <a href="https://app.elofrete.com.br/static/login.xhtml" target="_blank" class="button-link w-2">Acesse Aqui</a>
    </section>
  </template>
  
  <script>
  export default {
    name: 'Banner',
  };
  </script>
  
  <style scoped>
  .banner {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  
  .button-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    background-color: #e67600; /* Cor do fundo */
    color: white; /* Cor do texto */
    text-align: center;
    border-radius: 0.5rem; /* Bordas arredondadas */
    text-decoration: none; /* Remover sublinhado */
    font-weight: bold; /* Texto em negrito */
    transition: background-color 0.3s; /* Transição suave para hover */
    margin-left: 20px; /* Espaço entre o texto e o botão */
  }
  
  .button-link:hover {
    background-color: #326fd1; /* Cor do fundo no hover */
  }
  
  @media screen and (max-width: 768px) {
    .banner {
      flex-direction: column;
      /* Alinha os itens em coluna em telas menores */
    }
  
    .banner Button {
      margin-left: 0;
      margin-top: 10px;
    }
  
    .button-link {
      width: 100%;
      text-align: center;
      margin-left: 0;
    }
  }
  </style>
  