<template>
  <section id="vehicles" class="vehicles-section py-6 px-4 lg:px-8">
    <div class="text-center mb-6">
      <h2 class="text-3xl lg:text-5xl font-bold text-gray-900">
        VEÍCULOS <span class="text-blue-600">Mais de 1000 motoristas cadastrados</span>
      </h2>
    </div>
    <div class="vehicles-container grid grid-nogutter">
      <div
        v-for="(vehicle, index) in vehicles"
        :key="index"
        class="vehicle-item col-12 md:col-6 lg:col-4 p-2"
      >
        <div class="card">
          <div class="card-header flex align-items-center">
            <img src="https://bjjcontrol.com.br/no-image.png" alt="Logo Bloqueado" class="vehicle-image" />
            <div class="vehicle-details ml-2">
              <p style="text-align: center!important; width: 100%;">Produto: {{ vehicle.productDescription }}</p>
              <p >
              <span >De: {{ vehicle.originCity }}/{{ vehicle.originState }}</span>
              <span >Para: {{ vehicle.destinationCity }}/{{ vehicle.destinationState }}</span>
              <span >{{ vehicle.tipoCarga }}</span>
              <span >{{ vehicle.categoria }}</span>
            </p>
            </div>
            
          </div>
          <div class="card-body flex justify-content-between align-items-center">
            <span>{{ vehicle.category}}</span>
          </div>
          <div class="card-body flex justify-content-between align-items-center">
            <span>{{ vehicle.bodywork}}</span>
          </div>
          <div class="card-body flex justify-content-between align-items-center">
            
            
            <span class="vehicle-price">R$ •••••</span>
          </div>
          <a href="https://app.elofrete.com.br/" target="_blank"  class="button-link w-full">Veja Mais</a>
        </div>
      </div>
    </div>
  </section>
</template>
  
  <script>
  export default {
    data() {
      return {
        vehicles: [],
      };
    },
    mounted() {
      this.fetchVehicles();
    },
    methods: {
      fetchVehicles() {
        fetch('https://server1.connectas.com.br/elofrete-back-1.0/api/v1/shippings/public')
          .then((response) => response.json())
          .then((data) => {
            this.vehicles = data.data.content;
          })
          .catch((error) => console.error('Error fetching vehicles:', error));
      },
    },
  };
  </script>
  
  <style scoped>
  .vehicles-section {
  background-color: #ffffff;
}

.vehicle-item {
  display: flex;
  justify-content: center;
}

.card {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
}

.card-header {
  background-color: #f1f1f1;
  padding: 10px;
  display: flex;
  align-items: center;
}

.vehicle-image {
  width: 40px;
  height: 40px;
}

.vehicle-details {
  display: flex;
  flex-wrap: wrap;
}

.vehicle-details span {
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 12px;
}

.vehicle-price {
  font-size: 18px;
  font-weight: bold;
}

.card-body {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .vehicles-section h2 {
    font-size: 2rem;
  }

  .card-body h3 {
    font-size: 1.25rem;
  }

  .card-body p {
    font-size: 0.875rem;
  }
}
  </style>
  