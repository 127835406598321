<template>
  <header class="py-4 px-4 mx-0 lg:px-8 flex align-items-center justify-content-between fixed-header">
    <router-link to="/" class="flex align-items-center">
      <img :src="'layout/images/logo.svg'" alt="Elo Frete Logo" height="50" class="mr-2">
      <span class="text-800 font-medium line-height-3 mr-8">ELO FRETE</span>
    </router-link>
    <nav :class="{'hidden lg:flex space-x-6': true, 'block': isMenuOpen, 'lg:flex': isMenuOpen}" class="nav-style">
      <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
        <li>
          <a @click="smoothScroll('#hero')" class="px-4 py-2 text-900 font-medium line-height-3 p-ripple" v-ripple>
            <span>Início</span>
          </a>
        </li>
        <li>
          <a @click="smoothScroll('#features')" class="px-4 py-2 text-900 font-medium line-height-3 p-ripple" v-ripple>
            <span>Quem Somos</span>
          </a>
        </li>
        <li>
          <a @click="smoothScroll('#recurring-freight')" class="px-4 py-2 text-900 font-medium line-height-3 p-ripple" v-ripple>
            <span>Empresas</span>
          </a>
        </li>
        <li>
          <a @click="smoothScroll('#access-mobile')" class="px-4 py-2 text-900 font-medium line-height-3 p-ripple" v-ripple>
            <span>Motoristas</span>
          </a>
        </li>
        <!-- Novo item de menu para a seção Apoiadores -->
        <li>
          <a @click="smoothScroll('#apoiadores')" class="px-4 py-2 text-900 font-medium line-height-3 p-ripple" v-ripple>
            <span>Apoiadores</span>
          </a>
        </li>
      </ul>
    </nav>
    <a class="cursor-pointer block lg:hidden text-700 p-ripple" @click="toggleMenu" v-ripple>
      <i class="pi pi-bars text-4xl"></i>
    </a>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    smoothScroll(id) {
      document.querySelector(id).scrollIntoView({
        behavior: 'smooth',
      });
      this.isMenuOpen = false; // Fechar o menu ao clicar em um item de navegação
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style scoped>
/* Mesmos estilos existentes... */
</style>
