<template>
    <footer class="footer py-4 px-4 mx-0 mt-8 lg:mx-8" id="contact">
      <div class="grid justify-content-between align-items-center">
        <div class="col-12 md:col-4" style="margin-top:-1.5rem;">
          <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
            <img :src="'layout/images/logo-texto-2.svg'" alt="footer sections" width="250" height="250" class="mr-2">
          </div>
        </div>
        <div class="col-12 md:col-8">
          <h2>{{ responseMessage }}</h2>
          <form @submit.prevent="submitForm" class="email-form">
            <div class="flex flex-wrap items-center justify-center md:justify-end">
              <input v-model="name" type="text" placeholder="Seu nome" class="form-input" required />
              <input v-model="email" type="email" placeholder="Seu email" class="form-input" required />
              <input v-model="subject" type="text" placeholder="Assunto" class="form-input" required />
              <textarea v-model="message" placeholder="Mensagem" class="form-textarea" required></textarea>
              <button type="submit" class="submit-button" v-if="!isLoading">Enviar</button>
            </div>
          </form>
        </div>
        
        <div class="text-center w-full">
          © 2024 EloFrete | todos os direitos reservados | Desenvolvido por <a href="https://connectas.com.br" target="_blank">Connectas</a> ❤️
        </div>
      </div>
    </footer>
  </template>
  
  
  <script>
export default {
  name: 'Footer',
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      isLoading: false,
      responseMessage: '',
    };
  },
  methods: {
    async submitForm() {
      this.isLoading = true;
      this.responseMessage = '';
      const payload = {
        name: this.name,
        recipient: this.email,
        subject: this.subject,
        body: this.message,
      };


      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', 'Basic ' + btoa('fabio.godoy@connectas.com.br:d3b4c57d-5004-428e-a398-6aacbac1027f'));

      try {
        const response = await fetch('https://server1.connectas.com.br/email-service-1.0/api/v1/emails/to-admins', {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(payload),
        });


        if (response.ok) {
          this.responseMessage = 'Email enviado com sucesso!';
          this.resetForm();
        } else {
          this.responseMessage = 'Falha no envio do email.';
        }
      } catch (error) {
        this.responseMessage = 'Erro ao enviar o email: ' + error.message;
      } finally {
        this.isLoading = false;
      }
    },
    resetForm() {
      this.name = '';
      this.email = '';
      this.subject = '';
      this.message = '';
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #f8f9fa;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.email-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.form-textarea {
  height: 100px;
}

.submit-button {
  width: 100%;
  padding: 0.5rem;
  background-color: #007bff;
  border: 1px solid #007bff;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 768px) {
  .email-form {
    flex-direction: column;
  }
}
</style>
