<template>
    <div class="fab-container">
      <a :href="whatsappLink" target="_blank" class="fab">
        <img src="layout/images/whats.png" alt="WhatsApp" class="fab-icon" />
      </a>
    </div>
  </template>
<script>
export default {
  name: 'WhatsAppFAB',
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: 'Olá, pode me ajudar?',
    },
  },
  computed: {
    whatsappLink() {
      return `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(this.message)}`;
    },
  },
};
</script>
<style scoped>
.fab-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.fab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #25d366;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.fab-icon {
  width: 30px;
  height: 30px;
}
</style>
  