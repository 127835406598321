<template>
    <WhatsAppFAB phoneNumber="5548991672226" message="Olá, cheguei através do site e tenho algumas dúvidas, pode me ajudar?" />
    <div class="surface-0 overflow-hidden">
        <Header />

        <div class="grid py-4 px-4 lg:px-8 relative" id="hero">
            <div id="hero-content" class="hero-content">
                <h1 class="text-4xl lg:text-6xl font-bold text-blue-900">Encontre aqui o melhor frete.</h1>
                <p class="text-lg lg:text-xl text-orange-600">Empresa, encontre o melhor transportador</p>
                <p class="text-lg lg:text-xl text-blue-600">Motorista, encontre a melhor carga</p>
                <p class="text-lg lg:text-xl text-blue-900 mt-4">Escolha seu perfil. A partir da escolha iremos entregar
                    uma experiência única de acordo com as suas preferências</p>
                <div class="flex flex-wrap gap-4 mt-4">
                    <a href="https://app.elofrete.com.br/static/shipper/index.html" target="_blank"  class="button-link">EMBARCADORES</a>
                    <a href="https://app.elofrete.com.br/static/industry/index.html" target="_blank"  class="button-link">INDÚSTRIAS</a>
                    <a href="https://app.elofrete.com.br/static/carrier/index.html" target="_blank"  class="button-link">TRANSPORTADORAS</a>
                    <a href="https://app.elofrete.com.br/static/agent/index.html" target="_blank"  class="button-link">AGENCIADORES</a>
                    <a href="https://app.elofrete.com.br/static/driver/index.html" target="_blank"  class="button-link">MOTORISTAS</a>
                </div>
                <p class="text-lg lg:text-xl text-blue-900 mt-4">JÁ É CADASTRADO?</p>
                <div class="flex flex-wrap gap-4 mt-4">
                    <a href="https://app.elofrete.com.br/static/login.xhtml" target="_blank"  class="button-link">ACESSE AQUI</a>
                </div>
            </div>
            <img src="layout/images/elofrete-1-copiar.png" alt="hero screen" class="hero-image">
        </div>

        <section id="features" class="steps-section py-6 px-4 lg:px-8">
            <div class="text-center mb-6">
                <h2 class="text-3xl lg:text-5xl font-bold text-gray-900">Encontre e agende seu frete em <span
                        class="text-orange-600">poucos passos</span></h2>
            </div>
            <div class="steps-container flex flex-wrap justify-center">
                <div class="step-item text-center p-4 relative">
                    <span class="step-number">01</span>
                    <img src="layout/images/step-1.svg" alt="Data & Locais" class="step-image mx-auto mb-4">
                    <h3 class="text-xl font-bold text-gray-900">Data & Locais</h3>
                    <p class="text-gray-600">Consulte a disponibilidade</p>
                </div>
                <div class="step-item text-center p-4 relative">
                    <span class="step-number">02</span>
                    <img src="layout/images/step-2.svg" alt="Selecione o veículo" class="step-image mx-auto mb-4">
                    <h3 class="text-xl font-bold text-gray-900">Selecione o veículo</h3>
                    <p class="text-gray-600">Cargas especiais precisam de veículos especiais</p>
                </div>
                <div class="step-item text-center p-4 relative">
                    <span class="step-number">03</span>
                    <img src="layout/images/step-3.svg" alt="Faça o agendamento" class="step-image mx-auto mb-4">
                    <h3 class="text-xl font-bold text-gray-900">Faça o agendamento</h3>
                    <p class="text-gray-600">De forma prática você seleciona e contrata o frete</p>
                </div>
                <div class="step-item text-center p-4 relative">
                    <span class="step-number">04</span>
                    <img src="layout/images/step-4.svg" alt="Acompanhe o frete" class="step-image mx-auto mb-4">
                    <h3 class="text-xl font-bold text-gray-900">Acompanhe o frete</h3>
                    <p class="text-gray-600">Pelo App Elo Frete você acompanha sua carga</p>
                </div>
            </div>
        </section>

        <section id="highlight" class="experience-section py-6 px-4 lg:px-8">
            <div class="grid grid-nogutter align-items-center">
                <div class="col-12 lg:col-6">
                    <img src="layout/images/elofrete-2-2.png" alt="Experiência e Competência"
                        class="experience-image w-full h-auto rounded-lg">
                </div>
                <div class="col-12 lg:col-6 p-4 lg:p-8 text-justify">
                    <h2 class="text-3xl lg:text-5xl font-bold text-blue-900">Experiência & Competência</h2>
                    <p class="text-lg text-gray-700 mt-4">Trabalhamos para encurtar caminhos entre quem precisa e quem
                        fornece.</p>
                    <p class="text-lg text-gray-700 mt-4">O sistema EloFrete permite que você faça o melhor negócio com
                        o melhor custo x benefício pois temos em nosso portfólio mais 2000 cadastros entre empresas e
                        transportadores prontos para colocar a mão no trabalho.</p>
                    <p class="text-lg text-gray-700 mt-4">Conte com nosso apoio para crescer seu negócio.</p>
                    <a href="https://app.elofrete.com.br/static/signup.xhtml" target="_blank"  class="button-link w-full">Cadastre-se</a>
                    <p class="text-lg text-gray-700 mt-4">JÁ É CADASTRADO?</p>
                    <a href="https://app.elofrete.com.br/static/login.xhtml" target="_blank"  class="button-link w-full">ACESSE AQUI</a>
                </div>
            </div>
        </section>

        <VehicleSection />

        <SponsorsSection />

        <Banner />
        
        <RecurringFreightSection />

        <AccessMobileSection />


        <Banner />

        <Footer />
    </div>
    
</template>

<script>
import VehicleSection from '../components/VehicleSection.vue';
import RecurringFreightSection from '../components/RecurringFreightSection.vue';
import AccessMobileSection from '../components/AccessMobileSection.vue';
import WhatsAppFAB from '../components/WhatsAppFAB.vue';
import Banner from '../components/Banner.vue';
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import SponsorsSection from '../components/Sponsors.vue';


export default {
    methods: {
        smoothScroll(id) {
            document.querySelector(id).scrollIntoView({
                behavior: 'smooth'
            });
        }
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    },
    components: {
        VehicleSection,
        RecurringFreightSection,
        AccessMobileSection,
        WhatsAppFAB,
        Banner,
        Header,
        Footer,
        SponsorsSection,
    },
}
</script>

<style>

.button-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #ff8c00; /* Cor do fundo */
  color: white; /* Cor do texto */
  text-align: center;
  border-radius: 0.5rem; /* Bordas arredondadas */
  text-decoration: none; /* Remover sublinhado */
  font-weight: bold; /* Texto em negrito */
  transition: background-color 0.3s; /* Transição suave para hover */
}

.button-link:hover {
  background-color: #326fd1; /* Cor do fundo no hover */
}


.elofrete-blue{
    color: #326fd1;
}

.bg-elofrete-blue{
    background-color: #326fd1;
}

.elofrete-orange{
    color: #ff8c00;
}

.bg-elofrete-orange{
    background-color: #ff8c00;
}

#hero {
    /*background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), radial-gradient(77.36% 256.97% at 77.36% 57.52%, #EEEFAF 0%, #C3E3FA 100%);*/
    background: white;
    height: 100vh;
    /* Altura ajustada para ocupar a tela inteira */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    padding: 20px;
}

.hero-content {
    max-width: 50%;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.9);
    /* Fundo branco semitransparente */
    border: 2px solid #003366;
    /* Cor azul */
    border-radius: 15px;
    /* Cantos arredondados */
    padding: 20px;
    /* Espaçamento interno */
    position: absolute;
    left: 50px;
    /* Ajuste conforme necessário */
    top: 50%;
    /* Centraliza verticalmente */
    transform: translateY(-50%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Sombra para destaque */
}

.hero-image {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    max-width: 50%;
    object-fit: cover;
    z-index: 1;
}

.steps-section {
    background-color: white;
    padding: 60px 20px;
    margin-top: 20px;
}

.steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.step-item {
    flex: 1 1 200px;
    max-width: 300px;
    position: relative;
}

.step-image {
    width: 100px;
    height: 100px;
}

.step-number {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #FF6F00;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.steps-section h2 {
    font-size: 2.5rem;
    color: #333;
}

.steps-section h3 {
    font-size: 1.5rem;
    color: #333;
}

.steps-section p {
    font-size: 1rem;
    color: #666;
}

.experience-section {
    background-color: #ffffff;
    padding: 60px 20px;
}

.experience-image {
    border-radius: 10px;
    object-fit: cover;
}

.experience-section h2 {
    font-size: 2.5rem;
    color: #003366;
}

.experience-section p {
    font-size: 1rem;
    color: #666;
}

@media screen and (max-width: 1300px) {
    #hero {
        flex-direction: column;
        align-items: center;
        height: auto;
        /* Ajusta a altura automaticamente para telas menores */
    }

    .hero-content {
        max-width: 90%;
        text-align: center;
        position: relative;
        left: 0;
        top: 0;
        transform: none;
        margin-bottom: 20px;
    }

    .hero-image {
        position: static;
        max-width: 100%;
        height: auto;
        margin-top: -50px;
        /* Ajusta a imagem para melhor visualização */
    }
}

@media screen and (max-width: 768px) {
    .steps-section {
        padding: 40px 20px;
    }

    .steps-container {
        flex-direction: column;
        align-items: center;
    }

    .step-item {
        max-width: 100%;
        text-align: center;
    }

    .steps-section h2 {
        font-size: 2rem;
    }

    .steps-section h3 {
        font-size: 1.25rem;
    }

    .experience-section {
        padding: 40px 20px;
    }

    .experience-section h2 {
        font-size: 2rem;
    }

    .experience-section p {
        font-size: 1rem;
    }

    .experience-image {
        margin-bottom: 20px;
    }

    
    
}
</style>