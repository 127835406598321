<template>
    <section id="recurring-freight" class="recurring-freight-section py-6 px-4 lg:px-8">
      <div class="container mx-auto border-rounded-lg p-6 border-blue-600">
        <div class="grid grid-nogutter align-items-center">
          <div class="col-12 lg:col-6 text-center lg:text-left">
            <img src="layout/images/logo.svg" alt="Elo Frete Logo" class="mx-auto lg:mx-0 logo" />
          </div>
          <div class="col-12 lg:col-6 text-center lg:text-left">
            <h3 class="text-xl text-orange-600">EMPRESAS / ASSOCIAÇÕES / SINDICATOS</h3>
            <h2 class="text-4xl lg:text-5xl font-bold text-blue-900 mt-4">Vocês têm <span class="text-orange-600">Fretes recorrentes?</span></h2>
            <p class="text-lg text-gray-800 mt-4">Nós temos a solução para agilizar seu transporte de forma rápida e econômica.</p>
            <a href="https://app.elofrete.com.br/static/signup.xhtml" target="_blank"  class="button-link w-full">Cadastre-se</a>
            <p class="text-lg text-gray-800 mt-4 text-center">OU</p>
            <a href="https://app.elofrete.com.br/static/signup.xhtml" target="_blank"  class="button-link w-full">ACESSE AQUI</a>
          </div>
          <div class="col-12 lg:col-6 text-center lg:text-left">
            <p class="text-lg text-gray-800 mt-4">O Sistema ELO FRETE responde de forma proativa na captação de parceiros para agilizar suas entregas de forma prática e segura.</p>
            <p class="text-lg text-blue-600 mt-4">Seus dados são criptografados e só são disponibilizados para os transportadores na contratação do serviço.</p>
          </div>
          <div class="col-12 lg:col-6 text-center lg:text-left">
            <div class="info-box p-4 border-orange-500 bg-white w-90">
              <h4 class="text-xl font-bold text-orange-600">Em nosso App você consegue filtrar por:</h4>
              <ul class="list-disc ml-6 mt-2 text-lg text-gray-800 text-left">
                <li>reputação,</li>
                <li>avaliações,</li>
                <li>capacidade de carga,</li>
                <li>tipo de carga,</li>
                <li>preços, etc.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
<script>
export default {
  name: 'RecurringFreightSection',
};
</script>
<style scoped>
.logo {
  transform: rotate(-10deg);
  opacity: 0.7;
}


.recurring-freight-section {
  background-color: #ffffff;
}

.container {
  border: 2px solid;
  border-radius: 15px;
}

.info-box {
  border: 2px solid;
  border-radius: 10px;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .recurring-freight-section h2 {
    font-size: 2rem;
  }

  .info-box h4 {
    font-size: 1.5rem;
  }

  .info-box ul {
    font-size: 1rem;
  }

  .logo {
    opacity: 0.7;
    width: 90%;
    margin-bottom: 20px;
  }
}
</style>

  