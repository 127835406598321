<template>
    <section id="access-mobile" class="access-mobile-section py-6 px-4 lg:px-8">
        <div class="grid grid-nogutter">
            <div class="col-12 lg:col-6  lg:text-left text-column" >
                <h1 class="font-bold text-blue-900 large-title">
                    Acesse <span class="text-orange-600">rápido</span> pelo seu celular
                </h1>
                <p class="text-lg text-gray-800 mt-4">
                    Acesse nosso portal pelos aplicativos disponibilizados nas lojas Google Play e Apple Store.
                </p>
                <p class="text-lg text-gray-800 mt-4">
                    Em nosso sistema você terá acesso a rápido a centenas de cotações de frete por mês para todo o
                    Brasil além de vantagens exclusivas que só a EloFrete tem pra oferecer.
                </p>
            </div>
            <div class="col-12 lg:col-6 text-center lg:text-left">
                <img src="layout/images/mobile-site-2.png" alt="Elo Frete App" class="mobile-mockup" />
            </div>
        </div>
    </section>
</template>


<script>
export default {
    name: 'AccessMobileSection',
};
</script>
<style scoped>
.large-title {
    font-size: 4rem;
}

.access-mobile-section {
    background-color: #ffffff;
    
}

.text-column{
    padding: 20px;
}

.text-container {
    padding: 20px;
}

.image-container {
    padding: 20px;
}

.mobile-mockup {
    max-width: 100%;
    height: auto;
    transform: rotate(-5deg);
    /* Ajuste a rotação conforme necessário */
}

@media screen and (max-width: 768px) {

    .text-container,
    .image-container {
        padding: 10px;
    }

    .mobile-mockup {
        max-width: 80%;
        transform: rotate(0deg);
    }
}
</style>
