<template>
    <section class="apoiadores-section" id="apoiadores">
      <h1 class="font-bold text-blue-900 apoiadores-title">Estes são os grandes 
        <span class="text-orange-600">APOIADORES</span> do Elo Frete</h1>

      <div class="categories-container">
        <div class="category" v-for="(category, index) in categories" :key="index">
          <a :href="category.link" target="_blank" rel="noopener noreferrer" class="category-link">
            <img :src="category.image" :alt="category.name" class="category-image" />
        </a>
        <p class="font-bold text-blue-900 category-name">{{ category.name }}</p>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'SponsorsSection',
    data() {
      return {
        categories: [
          { name: 'Veículos', image: 'https://via.placeholder.com/150', link: '#' },
          { name: 'Implementos', image: 'https://via.placeholder.com/150', link: '#' },
          { name: 'Seguro de cargas', image: 'https://via.placeholder.com/150', link: '#' },
          { name: 'Seguro de veículos', image: 'https://via.placeholder.com/150', link: '#' },
          { name: 'Gerenciadora de risco', image: 'https://via.placeholder.com/150', link: '#' },
          { name: 'Cooperativa de crédito', image: 'https://via.placeholder.com/150', link: '#' },
          { name: 'Pneus', image: 'https://via.placeholder.com/150', link: '#' },
          { name: 'Outros insumos', image: 'https://via.placeholder.com/150', link: '#' },
        ],
      };
    },
  };
  </script>
  
  <style scoped>
  .apoiadores-section {
    
    padding-bottom: 20px;
    text-align: center;
  }
  
  .apoiadores-title {
    font-size: 4rem;
    margin-bottom: 30px;
  }
  
  .categories-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 categorias por linha em telas grandes */
    gap: 20px;
    justify-content: center;
  }
  
  .category {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .category-link {
    text-decoration: none;
  }
  
  .category-image {
    width: 100%; /* Ajusta a largura da imagem para 100% do contêiner */
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .category-name {
    font-size: 2rem;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .categories-container {
      grid-template-columns: 1fr; /* 1 categoria por linha em telas pequenas */
    }
    
    .category-image {
      max-width: 100%; /* Garante que a imagem não ultrapasse a largura do contêiner */
    }
  }
  </style>
  